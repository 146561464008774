/* advertising-section */

.advertising-section {
  color: #ffffff;
  margin-top: -180px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../images/advocates-bg-top.png),
    url(../images/advertising-bg-bottom.svg);
  background-position: top 0 left 0, top 0 left 0;
  background-size: calc(100% + 3px) 480px, cover;
  border-radius: 0 200px 200px 200px;
  background-repeat: no-repeat;
  border-top-right-radius: unset;
}

.advertising-section .container {
  display: flex;
  flex-direction: column;
  padding: 330px 24px 120px 24px;
}

@media (max-width: 1439px) {
  .advertising-section {
    margin-top: calc(-12.5vw + calc((1440px - 100vw) / 8));
    background-size: calc(100% + 3px) max(33.33vw, 140px), cover;
  }

  .advertising-section .container {
    padding-top: max(25.91vw, 120px);
  }
}

.advertising-section h2 {
  max-width: 1000px;
  margin: 10px auto 24px;
  font-size: 40px;
  line-height: 49px;
  font-weight: 700;
  text-align: center;
}

.advertising-section p {
  max-width: 820px;
  margin: 0 auto 25px;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
}

.advertising-section img {
  margin: 90px auto 0 auto;
  display: block;
  width: 100%;
  height: auto;
  max-width: 826px;
}

.advertising-section .call-action {
  display: inline-block;
  width: auto;
  min-width: 230px;
  margin: -2rem auto 1rem;
  padding: 19px;
  font-size: 18px;
  line-height: 22px;
  background-color: #f9ae3b;
  border: 1px solid #f9ae3b;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 700;
  text-align: center;
  border: 0;
}

.advertising-section .advertising-proven-solution {
  margin: 0 auto;
  font-size: 14px;
  line-height: 17px;
}

.advertising-section .advertising-platform-banner-outer {
  max-width: 1000px;
  width: 100%;
  margin: 40px auto 20px;
}

.advertising-section .advertising-platform-banner {
  position: relative;
  width: 100%;
  padding-bottom: 57.625%;
  background-image: url(../images/home/publisher-platform.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.advertising-platform-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -30px;
}

.advertising-platform-play-button .play-video-button__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(90.15deg, #338dca -2.57%, #99c6e4 103.1%);
  opacity: 0.2;
}

.advertising-platform-play-button .play-video-button__inner {
  position: relative;
  width: 66px;
  height: 66px;
  background-size: 33px 33px;
}

@media (max-width: 959px) {
  .advertising-section h2 {
    margin: 0 auto 24px;
  }

  .advertising-section .advertising-platform-banner-outer {
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .advertising-section {
    margin-top: 0px;
    /* padding-top: 140px; */
    background-size: calc(100% + 3px) 140px, cover;
    border-radius: 0 80px 80px 80px;
  }

  .advertising-platform-index .advertising-platform-banner {
    min-height: 250px;
  }

  .advertising-section .container {
    padding: 120px 34px 65px 34px;
  }

  .advertising-section p {
    margin: 0 auto 25px;
    font-size: 16px;
    line-height: 20px;
  }

  .advertising-section img {
    margin: 0 auto;
  }

  .advertising-platform-play-button {
    width: 86px;
    height: 86px;
    margin-left: 10px;
    margin-top: -15px;
  }

  .advertising-platform-play-button .play-video-button__inner {
    width: 52px;
    height: 52px;
    background-size: 26px 26px;
  }
  .affiliate-programs .advertising-platform-banner {
    min-height: 0;
  }

  .advertising-section .call-action {
    min-width: 288px;
    max-width: 100%;
    margin: 0 auto 35px;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 18px;
  }
}

@media (max-width: 413px) {
  .advertising-section .call-action {
    min-width: auto;
    width: 100%;
    max-width: 288px;
  }
}

@media (max-width: 359px) {
  .advertising-section h2 {
    font-size: 32px;
    line-height: 39px;
    word-wrap: break-word;
  }

  .advertising-platform-play-button {
    width: 50px;
    height: 50px;
  }

  .advertising-platform-play-button .play-video-button__inner {
    width: 36px;
    height: 36px;
    background-size: 20px 20px;
  }
}
